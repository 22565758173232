<template>
  <div>
    <textarea
      v-model="model.cancelReason"
      :placeholder="placeholder"
      rows="3"
      class="form-control"
      :class="{ 'tw-border-red-500': errors.has('cancelReason') }"
    ></textarea>
    <div class="tw-text-red-500" v-if="errors.has('cancelReason')">
      {{ errors.first('cancelReason') }}
    </div>
  </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
export default {
  name: 'reasonDelete',
  props: ['modelValue', 'validate', 'placeholder'],
  data() {
    return {
      model: {},
      errors: new Errors()
    }
  },
  created() {
    this.model = this.modelValue
  },
  methods: {},
  watch: {
    validate: function(error) {
      this.errors = new Errors(error.errors)
    }
  }
}
</script>
