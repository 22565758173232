<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-3">
            <div class="row">
                <div class="col-md-12 tw-border tw-rounded tw-p-2">
                    <div class="tw-grid tw-grid-cols-3 tw-gap-2">
                        <div>
                            <p class="tw-m-0 tw-text-sm tw-text-blue-600">
                                {{ $t("requestDelivery.transactionNumber") }}
                            </p>
                            <p class="tw-m-0">{{ delivery.delivery_number }}</p>
                        </div>
                        <div>
                            <p class="tw-m-0 tw-text-sm tw-text-blue-600">
                                {{ $t("requestDelivery.shopName") }}
                            </p>
                            <p class="tw-m-0">{{ delivery.shop_name }}</p>
                        </div>
                        <div>
                            <p class="tw-m-0 tw-text-sm tw-text-blue-600">
                                {{ $t("requestDelivery.deliveryName") }}
                            </p>
                            <p class="tw-m-0">{{ delivery.driver_name }}</p>
                        </div>
                        <div>
                            <p class="tw-m-0 tw-text-sm tw-text-blue-600">
                                {{ $t("requestDelivery.receiverPhone") }}
                            </p>
                            <p class="tw-m-0">{{ delivery.receiver_phone }}</p>
                        </div>
                        <div>
                            <p class="tw-m-0 tw-text-sm tw-text-blue-600">
                                {{ $t("requestDelivery.receiverLocation") }}
                            </p>
                            <p class="tw-m-0">
                                {{ delivery.receiver_location }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="required">{{
                    $t("requestDelivery.status")
                }}</label>
                <a-select
                    v-model="model.delivery_status_id"
                    class="tw-w-full"
                    :options="deliveryStatus"
                ></a-select>
                <div
                    class="tw-text-red-500"
                    v-if="errors.has('delivery_status_id')"
                >
                    {{ errors.first("delivery_status_id") }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <ts-checkbox
                        v-model="model.is_fee_charge"
                        @change="onIsFeeCharge"
                    >
                        Charge Delivery Fee
                    </ts-checkbox>
                </div>
                <div class="col-md-6">
                    <a-select
                        v-model="model.fee_paid_by"
                        class="tw-w-full"
                        :disabled="!model.is_fee_charge"
                        :options="[
                            { value: '', label: '--Fee Paid By--' },
                            { value: 2, label: 'Shop' },
                            { value: 1, label: 'Buyer' }
                        ]"
                    ></a-select>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('fee_paid_by')"
                    >
                        {{ errors.first("fee_paid_by") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>{{ $t("requestDelivery.extraFee") }}</label>
                    <input
                        v-model.number="model.extra_fee"
                        step="any"
                        type="number"
                        :placeholder="$t('requestDelivery.extraFee')"
                        class="form-control"
                        style="width: 98%"
                    />
                    <div class="tw-text-red-500" v-if="errors.has('extra_fee')">
                        {{ errors.first("extra_fee") }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="required">{{
                        $t("requestDelivery.deliveryFee")
                    }}</label>
                    <input
                        v-model.number="model.delivery_fee"
                        step="any"
                        type="number"
                        :placeholder="$t('requestDelivery.deliveryFee')"
                        class="form-control"
                    />
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('delivery_fee')"
                    >
                        {{ errors.first("delivery_fee") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t("requestDelivery.remark")
                    }}</label>
                    <textarea
                        v-model="model.delivery_status_remark"
                        rows="2"
                        class="form-control"
                        :placeholder="$t('requestDelivery.remark')"
                    ></textarea>
                    <div
                        class="tw-text-red-500"
                        v-if="errors.has('delivery_status_remark')"
                    >
                        {{ errors.first("delivery_status_remark") }}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button @click.prevent="$emit('cancel')">{{
                        $t("cancel")
                    }}</ts-button>
                    <ts-button
                        color="primary"
                        :disabled="loading"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions } from "vuex";
export default {
    name: "isCharged",
    props: ["delivery"],
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
                delivery_id: null,
                delivery_status_id: null,
                fee_paid_by: "", // shop
                is_fee_charge: false,
                delivery_status_remark: "",
                extra_fee: 0,
                delivery_fee: 0
            }
        };
    },
    computed: {
        deliveryStatus() {
            return [
                {
                    label: "Cancelled",
                    value: 7
                },
                {
                    label: "Pending",
                    value: 8
                },
                {
                    label: "Return",
                    value: 9
                }
            ];
        }
    },
    created() {
        this.model.delivery_id = this.delivery.delivery_id;
        this.model.delivery_status_id = this.delivery.delivery_status_id;
        this.model.delivery_status_remark = this.delivery.delivery_status_remark;
        this.model.is_fee_charge = this.delivery.is_fee_charge;
        this.setDeliveryFee(this.delivery);
    },
    methods: {
        ...mapActions("agency/requestDelivery", ["updateCharge"]),
        onIsFeeCharge() {
            this.model.fee_paid_by = this.delivery.service_paid_by;
        },
        onUpdate() {
            this.loading = true;
            this.errors = new Errors();
            this.updateCharge({
                id: this.model.delivery_id,
                data: this.model
            })
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setDeliveryFee(record) {
            if (record.service_type_id == 1) {
                // express
                this.model.delivery_fee = record.express_fee_usd;
            }
            if (record.service_type_id == 2) {
                // standard
                this.model.delivery_fee = record.standard_fee_usd;
            }
        }
    }
};
</script>
